<template>
  <div :id="target" class="hubspot-form hubspot-form-wrapper"></div>
</template>

<style>
.hubspot-form {
  .submitted-message {
    p {
      @apply mb-4;
    }
  }

  .button-1-wrapper a {
    @apply flex items-center justify-center w-full px-8 py-3 font-medium text-white border border-transparent shadow bg-fr-cta-orange rounded-md hover:bg-fr-cta-orange/80 md:py-4 md:text-lg md:px-10;
  }
}

.hs_community_of_interest___hidden {
    @apply invisible h-0;
}
</style>

<script setup>
const props = defineProps({
  form: Object,
});

const target = "hsFormWrapper_" + props.form?.id;

onMounted(() => {
  const script = document.createElement("script");
  script.src = "https://js.hsforms.net/forms/v2.js";
  document.body.appendChild(script);
  script.addEventListener("load", () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "43635150",
        formId: props.form?.id,
        target: "#" + target,
        onFormReady: function (form) {
          // console.log("form", form);
          const hiddenFields = props?.form?.hiddenFields ?? [];
          // console.log("hiddenFields", hiddenFields);
          if (Array.isArray(hiddenFields)) {
            hiddenFields.forEach((field) => {
              // console.log("field.inputSelector", field.inputSelector);
              // console.log(
              //   "form?.querySelector(field.inputSelector)",
              //   form?.querySelector(field.inputSelector),
              // );
              const input = form?.querySelector(field.inputSelector);
              if (input) {
                input.value = field.inputValue;
              }
            });
          }
        },
        onFormSubmit: function (form) {
          var thankYouElement = document.querySelector('.hubspot-form');
          if (thankYouElement) {
            /*window.scroll({
              top: thankYouElement.offsetTop,
              behavior: 'smooth'
            });*/
            thankYouElement.scrollIntoView();
          }
        }
      });
    }
  });
});

const emit = defineEmits(["submit"]);

onMounted(() => {
  window.addEventListener("message", (event) => {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      // console.log("Form Submitted! Event data: ${event.data}");
      emit("submit");
    }
  });
});
</script>
